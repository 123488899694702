import React, { useContext } from 'react'
import UrlPattern from 'url-pattern'
import { useRouter } from 'next/router'

import ErrorBoundary from '../../shared/error/boundary'
import Trans from '../../shared/components/trans'

import AreaContext from '../../context/domain'

import '../../../scss/react/frontoffice/site-messages.scss'

interface SiteMessagesProps {
    roleType?: string
    roleId?: string
}

const SiteMessages = ({ roleType, roleId }: SiteMessagesProps) => {
    const { data } = useContext(AreaContext)
    const { pathname } = useRouter()

    if (!data?.siteMessages) return null

    const messages = data?.siteMessages
        .filter(({ message }) => !!message)
        .filter(({ visibility }) =>
            visibility.some((pattern) => {
                const matcher = new UrlPattern(`${pattern}`)
                return !!matcher.match(pathname)
            })
        )
        .filter(({ user }) => {
            if (!user) return true
            return user === roleType
        })
        .map(({ message }) => message)

    if (!messages.length) return null

    return (
        <ErrorBoundary>
            <div className="site-messages">
                <div className="inner">
                    {messages.map((message) => (
                        <div key={message} className="site-message">
                            <Trans
                                label={message}
                                markdown
                                values={{ locationId: roleId }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </ErrorBoundary>
    )
}

export default SiteMessages
