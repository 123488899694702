import React from 'react'
import classnames from 'classnames'

import ErrorBoundary from '../../shared/error/boundary'

import '../../../scss/react/frontoffice/site-content.scss'

export interface SiteContentProps {
    className?: string
    children: React.ReactNode
}

const SiteContent = ({ className, children }: SiteContentProps) => (
    <ErrorBoundary>
        <div className={classnames('site-content', className)}>
            <div className="inner">{children}</div>
        </div>
    </ErrorBoundary>
)

export default SiteContent
