import { t } from '@zupr/i18n'
import { useCallback, useContext, useState } from 'react'
import { DomainContext } from '../../../../context/domain'
import Tray from '../../tray'

import LocationSearch from '../../search/location'
import ShoppingAreaSearch from '../../search/shopping-area'

import { ReactComponent as SearchIcon } from '../../../../../svg/search.svg'

const MobileSearchButton = () => {
    const { type, location } = useContext(DomainContext)

    const [open, setOpen] = useState(false)

    const handleOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <button
                className="inline"
                aria-label={t('Search')}
                onClick={handleOpen}
            >
                <span>
                    <SearchIcon />
                </span>
            </button>
            <Tray sidebar open={open} onClose={handleClose}>
                <h3>{t('Search')}</h3>
                <div className="tray-form">
                    {type === 'area' && (
                        <ShoppingAreaSearch onClick={handleClose} />
                    )}
                    {type === 'location' && (
                        <LocationSearch
                            location={location}
                            onClick={handleClose}
                        />
                    )}
                </div>
            </Tray>
        </>
    )
}

export default MobileSearchButton
