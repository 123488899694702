import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import React from 'react'
// import { createClient as createWSClient } from 'graphql-ws'

import {
    Provider as GraphqlProvider,
    cacheExchange,
    createClient,
    fetchExchange,
} from 'urql'

import { setApiHost, setImageProviderHost } from '@zupr/api/host'

import { SiteProps } from '@zupr/types/next'
import { DomainProvider } from '../context/domain'
import { GeolocationProvider } from '../context/geolocation'
import { ModalProvider } from '../context/modal'
import { RouteProvider } from '../context/route'
import { ShopperProvider } from '../context/shopper'
import { ShoppingListProvider } from '../context/shopping-list'
import { TrackingProvider } from '../context/tracking'
import { UxProvider } from '../context/ux'

const { publicRuntimeConfig } = getConfig()

setApiHost(`https://${publicRuntimeConfig.NEXT_PUBLIC_HOST_API}`)
setImageProviderHost(publicRuntimeConfig.NEXT_PUBLIC_IMAGE_PROVIDER_URL)

// const wsClient =
//     typeof window !== 'undefined' &&
//     createWSClient({
//         url: `wss://${publicRuntimeConfig.NEXT_PUBLIC_HOST_API}/graphql/`,
//     })

const graphqlClient = createClient({
    url: `https://${publicRuntimeConfig.NEXT_PUBLIC_HOST_API}/graphql/`,
    exchanges: [
        cacheExchange,
        fetchExchange,
        //     subscriptionExchange({
        //         forwardSubscription: (request) => ({
        //             subscribe: (sink) => ({
        //                 unsubscribe: wsClient.subscribe(request, sink),
        //             }),
        //         }),
        //     }),
    ],
})

const CookieConsent = dynamic(() => import('../shared/cookie/consent'), {
    ssr: false,
})

interface ProviderProps extends SiteProps {
    children?: React.ReactNode
}

const Providers = ({
    children,
    domainConfig,
    query,
    params,
    referer,
    userAgent,
}: ProviderProps) => (
    <GraphqlProvider value={graphqlClient}>
        <DomainProvider config={domainConfig}>
            <TrackingProvider>
                <RouteProvider referer={referer} query={query} params={params}>
                    <UxProvider userAgent={userAgent}>
                        <GeolocationProvider>
                            <ShopperProvider>
                                <ShoppingListProvider>
                                    <ModalProvider>
                                        {children}
                                        <CookieConsent />
                                    </ModalProvider>
                                </ShoppingListProvider>
                            </ShopperProvider>
                        </GeolocationProvider>
                    </UxProvider>
                </RouteProvider>
            </TrackingProvider>
        </DomainProvider>
    </GraphqlProvider>
)

export default Providers
