import React from 'react'
import dynamic from 'next/dynamic'

import Boundary from '../../shared/error/boundary'

const ShoppingList = dynamic(
    () => import('../features/shopping-list/shopping-list'),
    {
        ssr: false,
    }
)

const FrontofficeBase = ({ children }) => (
    <React.Fragment>
        <Boundary>{children}</Boundary>
        <Boundary>
            <ShoppingList />
        </Boundary>
    </React.Fragment>
)

export default FrontofficeBase
