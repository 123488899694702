import { useContext } from 'react'

import { DomainContext } from '../../../context/domain'
import LocationTop from './location'
import ShoppingAreaTop from './shopping-area'

const SiteTop = () => {
    const { type } = useContext(DomainContext)
    if (type === 'location') return <LocationTop />
    return <ShoppingAreaTop />
}

export default SiteTop
