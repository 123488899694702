import React from 'react'
import { openToday } from '@zupr/utils/hours'
import { Location } from '@zupr/types/fo'

import Trans from '../../components/trans'

export const WhenOpen = ({ opening_hours }: Location) => {
    if (!opening_hours || !Object.keys(opening_hours).length) {
        return <Trans label="Opening hours unknown" context="opening-hours" />
    }

    const today = openToday(opening_hours)

    if (today.now || today.later) {
        return (
            <Trans
                label="Open from %{opening} till %{closing}"
                context="opening-hours"
                values={today}
            />
        )
    }

    if (today.hours)
        return <Trans label="Closed for the day" context="opening-hours" />

    return <Trans label="Closed" context="opening-hours" />
}

export default WhenOpen
