import React, { useContext } from 'react'
import Link from 'next/link'

import DomainContext from '../../../context/domain'
import ExternalLink from '../../../shared/router/link-external'
import Trans from '../../../shared/components/trans'

import FooterCopyright from './components/copyright'

import OpeningHours from '../../../shared/location/hours/table'
import PhoneLink from '../../../shared/location/links/phone'
import SocialLink, { WhatsappLink } from '../../../shared/location/links/social'
import EmailLink from '../../../shared/location/links/email'
import { locationUrl } from '@zupr/utils/url'

const LocationFooter = () => {
    const { location } = useContext(DomainContext)
    return (
        <React.Fragment>
            <div className="site-footer footer-links">
                <div className="inner">
                    <div>
                        <h4>
                            <Trans
                                label="About %{location}"
                                values={{ location: location.title }}
                            />
                        </h4>
                        <ul>
                            <li>
                                <Link
                                    href={locationUrl(location, 'information')}
                                >
                                    <a>
                                        <Trans label="Information" />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={locationUrl(location, 'information')}
                                >
                                    <a>
                                        <Trans
                                            dutch
                                            label="Adres & openingstijden"
                                        />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href={locationUrl(location, 'information')}
                                >
                                    <a>
                                        <Trans dutch label="Betalen" />
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4>
                            <Trans dutch label="Neem contact op" />
                        </h4>
                        <ul>
                            {location.phone && (
                                <li>
                                    <PhoneLink noIcon location={location} />
                                </li>
                            )}
                            {location.whatsapp && (
                                <li>
                                    <WhatsappLink noIcon location={location} />
                                </li>
                            )}
                            {location.email && (
                                <li>
                                    <EmailLink noIcon location={location} />
                                </li>
                            )}
                            {(location.facebook || location.instagram) && (
                                <li>
                                    {location.facebook && (
                                        <SocialLink
                                            platform="facebook"
                                            location={location}
                                            href={location.facebook}
                                            iconOnly
                                        />
                                    )}
                                    {location.instagram && (
                                        <SocialLink
                                            platform="instagram"
                                            location={location}
                                            href={location.instagram}
                                            iconOnly
                                        />
                                    )}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div>
                        <h4>
                            <Trans label="Opening hours" />
                        </h4>
                        <OpeningHours hours={location.opening_hours} />
                    </div>
                    <div>
                        <h4>
                            <Trans dutch label="General" />
                        </h4>
                        <ul>
                            <li>
                                <ExternalLink href="https://www.zupr.io/nl/algemene-voorwaarden">
                                    <Trans dutch label="Algemene voorwaarden" />
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink href="https://www.zupr.io/nl/gebruiksvoorwaarden">
                                    <Trans dutch label="Gebruiksvoorwaarden" />
                                </ExternalLink>
                            </li>
                            <li>
                                <Link href="/cookies#cookiebeleid">
                                    <a>
                                        <Trans dutch label="Cookiebeleid" />
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <ExternalLink href="https://www.zupr.io/">
                                    <Trans dutch label="Over zupr" />
                                </ExternalLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <FooterCopyright />
        </React.Fragment>
    )
}

export default LocationFooter
