import React, { useContext } from 'react'
import { DomainContext } from '../../../context/domain'

import LocationFooter from './location'
import ShoppingAreaFooter from './shopping-area'

import '../../../../scss/react/frontoffice/site-footer.scss'

const SiteTop = () => {
    const { type } = useContext(DomainContext)
    if (type === 'location') return <LocationFooter />
    return <ShoppingAreaFooter />
}

export default SiteTop
