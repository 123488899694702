import classnames from 'classnames'
import Link from 'next/link'
import SkeletonLoading from '../../components/skeleton-loading'
import LinkToSelf from '../../router/link-to-self'

interface Props {
    url?: string
    title?: string
    subtitle?: string
    active?: string
    onClick?: () => void
}

export const SearchResultLinkSkeleton = () => (
    <li>
        <LinkToSelf>
            <SkeletonLoading lineHeight={16} />
        </LinkToSelf>
    </li>
)

const SearchResultLink = ({ url, title, subtitle, active, onClick }: Props) => {
    return (
        <li className={classnames('omni-search-link', { active })}>
            <Link href={url}>
                <a onClick={onClick}>
                    {title} <small>{subtitle}</small>
                </a>
            </Link>
        </li>
    )
}

export default SearchResultLink
