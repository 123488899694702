import { addQueryToUrl } from '@zupr/utils/url'
import { useMemo } from 'react'
import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

export const useRequest = ({ url, variables }) => {
    const fullUrl = Object.keys(variables || {}).length
        ? addQueryToUrl(`/api/${url}`, variables)
        : `/api/${url}`

    const { data } = useSWR(fullUrl, fetcher)
    return useMemo(() => [data], [data])
}
