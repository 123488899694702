import React from 'react'
import Link from 'next/link'

import ExternalLink from '../../../shared/router/link-external'
import Trans from '../../../shared/components/trans'

import FooterCopyright from './components/copyright'

const ShoppingAreaFooter = () => (
    <React.Fragment>
        <div className="site-footer footer-links">
            <div className="inner">
                <div>
                    <h4>
                        <Trans dutch label="Verkennen" />
                    </h4>
                    <ul>
                        <li>
                            <Link href="/winkels">
                                <a>
                                    <Trans dutch label="Winkels" />
                                </a>
                            </Link>
                            {' / '}
                            <Link href="/winkels/categorieen">
                                <a>
                                    <Trans dutch label="Categorieën" />
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/producten">
                                <a>
                                    <Trans dutch label="Producten" />
                                </a>
                            </Link>
                            {' / '}
                            <Link href="/producten/categorieen">
                                <a>
                                    <Trans dutch label="Categorieën" />
                                </a>
                            </Link>
                        </li>
                        <li>
                            <Link href="/ondernemers">
                                <a>
                                    <Trans dutch label="Ondernemers" />
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>
                        <Trans dutch label="Legal" />
                    </h4>
                    <ul>
                        <li>
                            <ExternalLink href="https://www.zupr.io/nl/algemene-voorwaarden">
                                <Trans dutch label="Algemene voorwaarden" />
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink href="https://www.zupr.io/nl/gebruiksvoorwaarden">
                                <Trans dutch label="Gebruiksvoorwaarden" />
                            </ExternalLink>
                        </li>
                        <li>
                            <Link href="/cookies#cookiebeleid">
                                <a>
                                    <Trans dutch label="Cookiebeleid" />
                                </a>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>
                        <Trans dutch label="Over Zupr" />
                    </h4>
                    <ul>
                        <li>
                            <ExternalLink href="https://www.zupr.io/nl/merken">
                                <Trans dutch label="Merken" />
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink href="https://www.zupr.io/nl/retailers">
                                <Trans dutch label="Retailers" />
                            </ExternalLink>
                        </li>
                        <li>
                            <ExternalLink href="https://www.zupr.io/nl/winkelgebieden">
                                <Trans dutch label="Winkelgebieden" />
                            </ExternalLink>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>
                        <Trans dutch label="Contact" />
                    </h4>
                    <ul>
                        {/* <li>
                            T. <a href="tel:+31854011306">085 4011306</a>
                        </li> */}
                        <li>
                            {/* E.{' '} */}
                            <a href="mailto:support@zupr.nl">
                                <Trans dutch label="support@zupr.nl" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <FooterCopyright />
    </React.Fragment>
)

export default ShoppingAreaFooter
