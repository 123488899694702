import { createClient } from 'redis'

export const client = createClient({
    url: process.env.REDIS_CONNECTION,
    socket: {
        reconnectStrategy: (attempts) => {
            if (attempts > 1000) {
                console.error('Too many retries on REDIS. Connection Terminated')
                return new Error('Too many retries.')
            }
            console.warn(`Redis reconnecting attempt ${attempts}`)
            return 1000
        },
    },
})
    // without listeners reconnect strategy doesn't work
    .on('connect', () => console.log('redis connect', new Date().toJSON()))
    .on('ready', () => console.log('redis ready', new Date().toJSON()))
    .on('error', (err) =>
        console.error('redis error', err, new Date().toJSON())
    )

if (process.env.REDIS_CONNECTION) {
    try {
        client.connect().catch(console.error)
    } catch (error) {
        console.error(error)
    }
}

export const getCache = async (key) => {
    try {
        // const startRequest = hrtime.bigint()

        const cached = await client
            .get(key)
            .catch(() => console.error('no redis connection'))

        if (cached) {
            return JSON.parse(cached)
        }
    } catch (err) {
        console.error(err)
    }

    return null
}

export const setCache = async (key, data, ex) => {
    try {
        client
            .set(key, JSON.stringify(data), { EX: 60 * (ex || 5) })
            .catch(() => console.error('no redis connection'))
    } catch (error) {
        console.error(error)
    }

    return null
}

export const invalidate = async (pattern) => {
    try {
        const lines = await client.scan(0, { MATCH: pattern, COUNT: 100 })
        if (lines.keys.length === 0) return lines
        await client.del(lines.keys)
        return lines
    } catch (error) {
        console.error(error)
    }
}

// function to invalidate cache in redis
