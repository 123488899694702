import React, { useCallback } from 'react'
import { locationUrl } from '@zupr/utils/url'
import { Location } from '@zupr/types/fo'

import Trans from '../../../shared/components/trans'

import NavLink from '../../../shared/router/navlink'

export interface MenuProps {
    location: Location
    className?: string
    onClick?: () => void
}

const LocationMenu = ({ location, className, onClick }: MenuProps) => {
    const isAssortmentActive = useCallback((isActive, route) => {
        if (route.pathname.includes('/product/')) return true
        return isActive
    }, [])
    const isMessagesActive = useCallback((isActive, route) => {
        if (route.pathname.includes('/message/')) return true
        return isActive
    }, [])
    return (
        <ul className={className}>
            {location.productsCount > 0 && (
                <li>
                    <NavLink
                        exact
                        href={locationUrl(location, 'products')}
                        isActive={isAssortmentActive}
                        onClick={onClick}
                    >
                        <Trans label="Assortment" />
                    </NavLink>
                </li>
            )}
            {location.messagesCount > 0 && (
                <li>
                    <NavLink
                        exact
                        href={locationUrl(location, 'messages')}
                        isActive={isMessagesActive}
                        onClick={onClick}
                    >
                        <Trans label="Messages" />
                    </NavLink>
                </li>
            )}
            <li>
                <NavLink
                    exact
                    href={locationUrl(location, 'information')}
                    onClick={onClick}
                >
                    <Trans label="Info" />
                </NavLink>
            </li>
            <li>
                <NavLink
                    exact
                    href={locationUrl(location, 'photos')}
                    onClick={onClick}
                >
                    <Trans label="Photos" />
                </NavLink>
            </li>
        </ul>
    )
}

export default LocationMenu
