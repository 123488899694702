import { useMemo } from 'react'
import { useRequest } from '@zupr/hooks/request-redux'
import { filterEmptyAggregations } from '@zupr/next/helpers/fo'

const useAggregations = ({ url, aggregation, variables }) => {
    const aggregationVariables = useMemo(
        () =>
            Object.keys(variables).reduce((obj, key) => {
                if ((aggregation.exclude || []).includes(key)) return obj
                return {
                    ...obj,
                    [key]: variables[key],
                }
            }, {}),
        [aggregation.exclude, variables]
    )

    const [aggregations, result] = useRequest({
        url,
        variables: {
            ...aggregationVariables,
            limit: 0,
            offset: 0,
            aggregations: Object.values(aggregation.keys).join(','),
        },
    })

    const data = useMemo(
        () =>
            aggregations &&
            filterEmptyAggregations(
                aggregations.aggregations,
                aggregation.base
            ),
        [aggregation.base, aggregations]
    )

    return useMemo(
        () => ({
            aggregation,
            url,
            variables,
            data,
            loading: result.fetching,
        }),
        [aggregation, data, result.fetching, url, variables]
    )
}

export default useAggregations
