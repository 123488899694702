import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import Link from 'next/link'
import { useCallback, useState } from 'react'

import LocationMenu from '../../../screens/location/menu'
import Tray from '../../tray'

import { ReactComponent as Hamburger } from '../../../../../svg/hamburger.svg'

import '../../../../../scss/react/frontoffice/site-menu.scss'

interface Props {
    location: Location
}

export const LocationMobileMenu = ({ location }: Props) => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const handleClose = useCallback(() => setMobileMenu(false), [])
    return (
        <>
            <button
                className="inline"
                aria-label={t('Menu')}
                onClick={() => {
                    setMobileMenu(!mobileMenu)
                }}
            >
                <span>
                    <Hamburger />
                </span>
            </button>
            <Tray
                sidebar
                className="mobile-menu-tray"
                open={mobileMenu}
                onClose={() => setMobileMenu(false)}
            >
                <div className="mobile-menu">
                    <Link href="/">
                        <a className="logo" onClick={handleClose}>
                            {location.title}
                        </a>
                    </Link>
                    <LocationMenu location={location} onClick={handleClose} />
                </div>
            </Tray>
        </>
    )
}

export default LocationMobileMenu
