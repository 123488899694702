export const proxyHeaders = (headers?: Headers | Record<string, string>): Record<string,string> => {

    if (!headers) return {}
    const xHeaders = {}

    if (headers instanceof Headers) {
        Object.keys(Object.fromEntries(headers.entries())).map((key) => {
            if (key.startsWith('x-') || key.startsWith('X-')) {
                xHeaders[key] = headers.get(key)
            }
        })
        if (!xHeaders['X-User-Agent'] && headers.has('user-agent')){
            xHeaders['X-User-Agent'] = headers.get('user-agent')
        }
        return xHeaders
    }

    // proxy all x headers 
    Object.keys(headers).forEach((key) => {
        if (key.startsWith('x-') || key.startsWith('X-')) {
            xHeaders[key] = headers[key]
        }
    })

    // proxy user agent header
    const userAgentKey = Object.keys(headers).find(key => key.toLowerCase() === 'user-agent')
    if (!xHeaders['X-User-Agent'] && userAgentKey){
        xHeaders['X-User-Agent'] = headers[userAgentKey]
    }

    return xHeaders
}