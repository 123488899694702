import { t } from '@zupr/i18n'
import Link from 'next/link'
import { useCallback, useContext, useState } from 'react'

import DomainContext from '../../../../context/domain'
import LinkExternal from '../../../../shared/router/link-external'
import NavLink from '../../../../shared/router/navlink'
import Categories from '../../../screens/categories/list'
import { MobileCategories } from '../../../screens/categories/menu'
import Tray from '../../tray'
import Logo from './logo'

import { ReactComponent as Hamburger } from '../../../../../svg/hamburger.svg'
import { ReactComponent as Triangle } from '../../../../../svg/triangle.svg'

import '../../../../../scss/react/frontoffice/site-menu.scss'

interface Props {
    onClick?: () => void
    onToggleCatergories: () => void
    showCategories: boolean
}

const Menu = ({ onToggleCatergories, showCategories, onClick }: Props) => {
    const { entrepreneurStories, data } = useContext(DomainContext)

    const handleProductLink = useCallback(
        (ev) => {
            ev.preventDefault() // do not follow link
            onToggleCatergories()
        },
        [onToggleCatergories]
    )

    return (
        <>
            <ul>
                <li className="more">
                    <NavLink
                        href="/producten"
                        isActive={() => showCategories}
                        onClick={handleProductLink}
                    >
                        {t('Categories')} <Triangle />
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        href="/winkels"
                        isActive={(active) => !!active && !showCategories}
                        onClick={onClick}
                    >
                        {t('Locations')}
                    </NavLink>
                </li>
                {data?.discover?.includes('discount') && (
                    <li>
                        <NavLink
                            href="/acties"
                            isActive={(active) => !!active && !showCategories}
                            onClick={onClick}
                        >
                            {t('Acties')}
                        </NavLink>
                    </li>
                )}
                {!!entrepreneurStories?.length && (
                    <li>
                        <NavLink
                            href="/ondernemers"
                            isActive={(active) => !!active && !showCategories}
                            onClick={onClick}
                        >
                            {t('Entrepreneurs')}
                        </NavLink>
                    </li>
                )}
            </ul>
            <ul>
                <li>
                    <LinkExternal href="https://support.zupr.nl">
                        {t('Klantenservice')}
                    </LinkExternal>
                </li>
                <li>
                    <Link href="/registreer">{t('Meld mijn winkel aan')}</Link>
                </li>
            </ul>
        </>
    )
}

const ShoppingAreaMenu = () => {
    const [showCategories, openCategories] = useState(false)

    const handleCategories = useCallback(() => {
        window.scrollTo(0, 0)
        openCategories(true)
    }, [])

    const handleCloseCategories = useCallback(() => {
        openCategories(false)
    }, [])

    const handleToggleCategories = useCallback(() => {
        if (showCategories) {
            handleCloseCategories()
        } else {
            handleCategories()
        }
    }, [handleCategories, handleCloseCategories, showCategories])

    return (
        <div className="site-menu">
            <div className="inner">
                <Menu
                    onToggleCatergories={handleToggleCategories}
                    showCategories={showCategories}
                />
            </div>
            <Tray open={showCategories} onClose={handleCloseCategories}>
                <Categories
                    renderFooter={
                        <Link href="/producten">
                            <a
                                className="more-products"
                                onClick={handleCloseCategories}
                            >
                                {t('View all products')}
                            </a>
                        </Link>
                    }
                    onClick={handleCloseCategories}
                />
            </Tray>
        </div>
    )
}

export const ShoppingAreaMobileMenu = () => {
    const [mobileMenu, setMobileMenu] = useState(false)
    const [showCategories, openCategories] = useState(false)

    const { brand } = useContext(DomainContext)

    const handleClose = useCallback(() => setMobileMenu(false), [])

    const handleCategories = useCallback(() => {
        openCategories(true)
    }, [])

    const handleCloseCategories = useCallback(() => {
        openCategories(false)
    }, [])

    return (
        <>
            <button
                className="inline"
                aria-label={t('Menu')}
                onClick={() => {
                    setMobileMenu(!mobileMenu)
                }}
            >
                <span>
                    <Hamburger />
                </span>
            </button>
            <Tray
                sidebar
                className="mobile-menu-tray"
                open={mobileMenu}
                onClose={handleClose}
            >
                <div className="mobile-menu">
                    <Link href="/">
                        <a className="logo" onClick={handleClose}>
                            {brand === 'Beezy' ? <Logo /> : <>{brand}</>}
                        </a>
                    </Link>
                    {!showCategories && (
                        <Menu
                            onToggleCatergories={handleCategories}
                            showCategories={showCategories}
                            onClick={handleClose}
                        />
                    )}
                </div>
                {showCategories && (
                    <MobileCategories
                        onClick={handleClose}
                        onCloseCategories={handleCloseCategories}
                    />
                )}
            </Tray>
        </>
    )
}
export default ShoppingAreaMenu
