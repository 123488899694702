import { useContext, useMemo } from 'react'

import ShoppingListContext from '../../../../context/shopping-list'

interface ShoppingListsStatus {
    isRequesting: boolean
    isAnswered: boolean
    isSeen: boolean
}

export const useShoppingListsStatus = (): ShoppingListsStatus => {
    const { lists } = useContext(ShoppingListContext)
    return useMemo(
        () => ({
            isRequesting: lists?.some(({ isRequesting }) => isRequesting),
            isAnswered: lists?.some(({ isAnswered }) => isAnswered),
            isSeen: lists?.every(
                ({ informationRequest, isSeen }) =>
                    !informationRequest || isSeen
            ),
        }),
        [lists]
    )
}
