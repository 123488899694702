import dynamic from 'next/dynamic'
import Link from 'next/link'

import { useIsMobile } from '../../../context/ux'
import ErrorBoundary from '../../../shared/error/boundary'
import OmniSearch from '../search/shopping-area'
import SiteMessages from '../site-messages'
import Buttons from './components/buttons'
import ShoppingAreaMenu, {
    ShoppingAreaMobileMenu,
} from './components/shopping-area-menu'

import '../../../../scss/react/frontoffice/site-header.scss'
import '../../../../scss/react/frontoffice/site-top.scss'

const Logo = dynamic(() => import('./components/logo'), {
    ssr: false,
})

const SiteTop = () => {
    const isMobile = useIsMobile()

    return (
        <ErrorBoundary>
            <div className="site-top">
                <div className="site-header">
                    <div className="inner">
                        <div className="branding">
                            {isMobile && <ShoppingAreaMobileMenu />}
                            <Link href="/">
                                <a className="logo">
                                    <Logo />
                                </a>
                            </Link>
                        </div>

                        {!isMobile && (
                            <div className="search">
                                <OmniSearch />
                            </div>
                        )}

                        <Buttons />
                    </div>
                </div>
                {!isMobile && <ShoppingAreaMenu />}
            </div>
            <SiteMessages />
        </ErrorBoundary>
    )
}

export default SiteTop
