import { gql } from 'urql'

export const onInformationRequestChanged = gql`
    subscription onInformationRequestChanged($id: UUID!) {
        onInformationRequestChanged(id: $id) {
            informationRequest {
                read
                answered
                modified
                productInformationRequests {
                    productId
                    quantityInStock
                    remarks
                    retailerRemarks
                }
            }
        }
    }
`

export const informationRequest = gql`
    query InformationRequest($id: UUID!) {
        informationRequest(id: $id) {
            read
            answered
            modified
            productInformationRequests {
                productId
                quantityInStock
                remarks
                retailerRemarks
            }
        }
    }
`
