import React from 'react'

import SiteFooter from './footer'
import SiteContent, { SiteContentProps } from './site-content'
import SiteTop from './top'

const SiteFull = ({ children, ...props }: SiteContentProps) => (
    <React.Fragment>
        <SiteTop />
        <SiteContent {...props}>{children}</SiteContent>
        <SiteFooter />
    </React.Fragment>
)

export default SiteFull
