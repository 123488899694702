import React from 'react'

import Categories from './menu'
import Themes from './themes'

interface CategoriesProps {
    onClick: () => void
    renderHeader?: React.ReactNode
    renderFooter?: React.ReactNode
}

const Catergories = ({
    onClick,
    renderHeader,
    renderFooter,
}: CategoriesProps) => (
    <div className="menu-holder">
        <Themes onClick={onClick} />
        {renderHeader}
        <Categories onClick={onClick} />
        {renderFooter}
    </div>
)

export default Catergories
