import { t } from '@zupr/i18n'
import Link from 'next/link'
import { useContext } from 'react'

import DomainContext from '../../../context/domain'
import useAggregations from '../../../hooks/aggregations'

import { ReactComponent as Chevron } from '../../../../svg/chevron.svg'

interface Props {
    onClick: () => void
}

const Themes = ({ onClick }: Props) => {
    const { shoppingAreaSlug, themes } = useContext(DomainContext)

    const aggregations = useAggregations({
        url: 'fo/variation',
        variables: {
            'product_locations.shopping_areas': shoppingAreaSlug,
        },
        aggregation: {
            base: 'product_locations',
            keys: {
                theme: 'product_locations.themes',
            },
        },
    })

    const themeAggregations = (
        aggregations.data?.['product_locations.themes']?.buckets || []
    ).filter(
        (themeAggregation) =>
            themeAggregation.key && themes?.includes(themeAggregation.key)
    )

    if (!themeAggregations.length) return null

    return (
        <ul className="themes-list">
            {themeAggregations.map((theme) => (
                <li key={theme.key}>
                    <Link href={`/producten?theme=${theme.key}`}>
                        <a className="btn btn-small" onClick={onClick}>
                            {t(theme.key, 'themes')}
                            <Chevron />
                        </a>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

export default Themes
